<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : ''"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : ''"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账商户</i>
            <el-input
              v-model="query.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="分账商户名称/编号"
            ></el-input>
          </div>
          <div class="item">
            <i class="jianju">所属商户</i>
            <el-select
              v-model="query.mchId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in mchDrop"
                :key="i"
                :label="v.mchName"
                :value="v.mchId"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i class="jianju">产品类型</i>
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in productDrop"
                :key="i"
                :label="v.productName"
                :value="v.productId"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="lineP">
          <div class="item">
            <i>分账状态</i>
            <el-select
              v-model="query.allocStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="分账处理中" value="PROCESSING"></el-option>
              <el-option label="分账成功" value="SUCCESS"></el-option>
              <el-option label="分账失败" value="FAIL"></el-option>
            </el-select>
          </div>
          <div class="item itemTime">
            <i>时间类型</i>
            <el-select
              v-model="query.timeType"
              placeholder="创建时间"
              style="width: 130px;"
            >
              <el-option label="创建时间" value="CREATE"></el-option>
              <el-option label="交易时间" value="TRADE"></el-option>
            </el-select>
            <span style="padding: 0 10px"></span>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="lineP">
          <div class="itembtn">
            <el-button type="primary" class="searchBt" @click="getLists"
              >查询</el-button
            >
            <el-button
              class="searchBt"
              v-auth="'OPERATION:ORDERS:ALLOC/SYNC'"
              @click="batchSync(1)"
              >批量同步通知</el-button
            >
            <el-button
              class="searchBt"
              v-auth="'OPERATION:ORDERS:ALLOC/IMPORT'"
              @click="openOrderImport"
              >订单导入</el-button
            > 
          </div>
        </div>
      </div>

      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 30px">
              <p>
                <el-checkbox
                  v-model="allSelect"
                  @change="parChange"
                ></el-checkbox>
              </p>
            </td>
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>分账订单号</p>
              <p>分账流水号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td style="width: 80px">
              <p>分账状态</p>
              <p>分账类型</p>
            </td>
            <td>
              <p>分账金额(元)</p>
            </td>
            <td>
              <p>是否担保</p>
            </td>
            <td style="padding-right: 10px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td style="width: 30px">
              <p>
                <el-checkbox
                  v-model="v.active"
                  @change="chidChange"
                ></el-checkbox>
              </p>
            </td>
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.mchAllocOrderNo }}</p>
              <p>{{ v.plfAllocOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td style="width: 80px">
              <p>{{ allocStatus[v.allocStatus] }}</p>
              <p>{{ allocFundType[v.allocFundType] }}</p>
            </td>
            <td>
              <p>{{ (v.allocAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.guaranteeStatus ? "是" : "否" }}</p>
            </td>
            <td style="padding-right: 10px">
              <p>
                <a
                  v-auth="'OPERATION:ORDERS:ALLOC/DETAIL'"
                  href="javascript:;"
                  @click="tabShow(v)"
                  class="lianjie"
                  >详情</a
                >
              </p>
              <p>
                <i
                  v-auth="'OPERATION:ORDERS:ALLOC/SYNC'"
                  class="lianjie"
                  @click="getSync(v.allocOrderId, i)"
                  >同步</i
                >
                <i
                  v-auth="'OPERATION:ORDERS:ALLOC/NOTIFY'"
                  v-if="v.allocStatus != 'PROCESSING'"
                  @click="getNotify(v.allocOrderId)"
                  class="lianjie"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
      <!--批量同步通知弹框 -->
      <el-dialog
        title="批量同步通知"
        :visible.sync="dialogShow"
        :close-on-click-modal="false"
        :show-close="false"
        :close-on-press-escape="false"
        center
        width="490px"
      >
        <div class="progressBox" style="height: 160px;box-sizing: border-box;">
          <el-progress
            type="circle"
            :percentage="percentage"
            :status="progressStatus"
            :width="130"
            :stroke-width="10"
          >
          </el-progress>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button
            class="addBt"
            :disabled="dialogDisabled"
            @click="startBatchNotify(1)"
            >批量通知</el-button
          >

          <el-button
            class="addBt"
            :disabled="exportSyncFlag"
            @click="handleDownload(2)"
            >{{ "导出同步结果" }}</el-button
          >
          <el-button
            class="addBt"
            :disabled="exportNotifyFlag"
            @click="handleDownload(3)"
            >{{ "导出通知结果" }}</el-button
          >
          <el-button
            class="addBt"
            :disabled="finishDisabled"
            @click="onclose()"
            >关 闭
          </el-button>
        </span>
      </el-dialog>
      <!-- 订单导入弹框 -->
      <el-dialog
        title="订单导入"
        :visible.sync="importShow"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="490px"
      >
        <div class="progressBox">
          <i class="required">*</i>
          <i>商户名称</i>
          <el-select
            v-model="selMchId"
            @change="setMchId"
            style="width: 200px; height: 36px; margin-left: 10px"
            placeholder="选择商户"
          >
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
        </div>
        <div class="uploadBox">
          <el-upload
            v-if="selMchId"
            class="upload-demo"
            accept=".xlsx"
            :action="actionUrl"
            :on-success="orderUploadSuccess"
            :before-remove="beforeRemove"
            :on-remove="removeOrderFile"
            :with-credentials="true"
            :limit="1"
            :file-list="fileList"
            :show-file-list="true"
            :on-exceed="handleExceed"
            :headers="headers"
          >
            <div slot="trigger" class="uploadButn">点击上传</div>
          </el-upload>
          <a
            class="tempText"
            v-if="selMchId"
            href="javascript:;"
            @click="handleDownload(1)"
            >模板下载</a
          >
        </div>
        <div></div>
      </el-dialog>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <!-- <i>退款订单号</i> -->
            <el-select
              v-model="formData.orderNoType"
              placeholder=""
              style="width: 170px;margin-right: 20px"
            >
              <el-option value="mchOrderNo" label="商户订单号"></el-option>
              <el-option value="mchAllocOrderNo" label="分账订单号"></el-option>
            </el-select>
            <el-input
              v-model="formData.orderNo"
              maxlength="128"
              style="width:calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[formData.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="searchBt" @click="getDetail">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1" style="min-height: 700px">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>分账订单号</p>
              <p>分账流水号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td style="width: 80px">
              <p>分账状态</p>
              <p>分账类型</p>
            </td>
            <td>
              <p>分账金额(元)</p>
            </td>
            <td>
              <p>是否担保</p>
            </td>
            <td style="padding-right: 10px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.mchAllocOrderNo }}</p>
              <p>{{ v.plfAllocOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td style="width: 80px">
              <p>{{ allocStatus[v.allocStatus] }}</p>
              <p>{{ allocFundType[v.allocFundType] }}</p>
            </td>
            <td>
              <p>{{ (v.allocAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.guaranteeStatus ? "是" : "否" }}</p>
            </td>
            <td style="padding-right: 10px">
              <p>
                <a
                  v-auth="'OPERATION:ORDERS:ALLOC/DETAIL'"
                  href="javascript:;"
                  @click="tabShow(v)"
                  class="lianjie"
                  >详情</a
                >
              </p>
              <p>
                <i
                  v-auth="'OPERATION:ORDERS:ALLOC/SYNC'"
                  class="lianjie"
                  @click="getSync(v.allocOrderId, i)"
                  >同步</i
                >
                <i
                  v-auth="'OPERATION:ORDERS:ALLOC/NOTIFY'"
                  v-if="v.allocStatus != 'PROCESSING'"
                  @click="getNotify(v.allocOrderId)"
                  class="lianjie"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getAllocfundOrders,
  getAllocfundSearch,
  splitTradeSync,
  splitTradeNotify,
} from "@/api/trading/subaccount.js";
import { payproductsDrop, merchantsDrop } from "@/api/common.js";
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
var token =  localStorage.getItem('token') // 上传文件带token
export default {
  data() {
    return {
      headers: {token: token }, // 上传文件带token
      query: {
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        allocStatus: "",
        mchId: "",
        productId: "",
        keyword: "",
      },
      productDrop: [],
      mchDrop: [], //商户下拉可选数据
      totalNum: 0,

      allocStatus: {
        PROCESSING: "分账处理中",
        FAIL: "分账失败",
        SUCCESS: "分账成功",
      },
      allocFundType: {
        11: "实时分账",
        12: "延迟分账",
        13: "多次分账",
      },
      formData: {
        orderNoType: "mchOrderNo",
        orderNo: "",
      },
      orderNoType: {
        mchOrderNo: "商户订单号",
        mchAllocOrderNo: "分账订单号",
      },
      tabData: [],
      crumbs: "批量查询",

      // ----------------------------------------------------------------------------------
      actionUrl: "", //订单导入上传链接
      uploadUrl: URL_API.baseURL + "/manager/operation/notices/upload/",
      selMchId: "", //订单导入时选中的商户Id

      allSelect: false, //全选
      dialogShow: false, //控制批量同步通知弹框
      dialogDisabled: false, //控制批量通知按钮
      exportSyncFlag: false, //控制导出批量同步结果按钮
      exportNotifyFlag: false, //控制导出批量通知结果按钮

      finishDisabled: false, //控制 关闭按钮
      percentage: 0, //进度值
      successesNum: 0, //统计批量同步通知成功次数的变量
      progressStatus: null, //环形进度条状态 如果选中的订单状态同步 上游接口部分完成，则显示！progressStatus= warning 全部完成,则显示√ ！progressStatus= success 全部异常，则显示× progressStatus= exception
      batchNotifyArry: [], //存放可以发送批量通知的数据
      batchSyncResList: [], //存放批量同步的结果数据
      batchNotifyResList: [], //存放批量通知的结果数据
      importShow: false, //控制订单导入弹框
      importSuccessList: [], //接收订单导入成功后返回的 orderId
      fileList: [], //订单上传的文件数据
    };
  },
  computed: {
    ...mapState({
      fzjyDetail: "operation_fzjy",
    }),
  },
  created() {
    if (this.fzjyDetail) {
      this.query = this.fzjyDetail.query;
      this.formData = this.fzjyDetail.formData;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
  methods: {
    ...mapMutations({
      setFzjyDetail: "operation_setFzjy",
    }),
    search() {
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        this.getDetail();
      }
    },

    // -----------------------------------------------------------------------------
    // 全选
    parChange() {
      if (this.allSelect) {
        this.tabData.forEach((v) => {
          v.active = true;
        });
      } else {
        this.tabData.forEach((v) => {
          v.active = false;
        });
      }
      this.tabData = JSON.parse(JSON.stringify(this.tabData));
    },
    //多选
    chidChange() {
      let length = 0;
      this.tabData.forEach((v) => {
        if (v.active) {
          length++;
        } else {
          this.allSelect = false;
        }
      });
      if (length == this.tabData.length) {
        this.allSelect = true;
      }
    },
    // 去掉数组里allocOrderId相同的一项,有allocOrderId相同的只保留其中任意一个
    filterArry(arry) {
      var arr = []; // 新建一个数组来存放arr中的值
      var ids = [];
      for (var i = 0, len = arry.length; i < len; i++) {
        if (ids.indexOf(arry[i].allocOrderId) === -1) {
          ids.push(arry[i].allocOrderId);
          arr.push(arry[i]);
        }
      }
      return arr;
    },

    // 点击批量同步通知按钮
    batchSync(val) {
      this.percentage = 0; //重置进度条进度值
      this.progressStatus = null; //重置进度条颜色
      this.successesNum = 0; //重置统计批量同步或批量通知成功次数的变量
      this.batchNotifyArry = []; //清空批量通知数据
      this.batchSyncResList = []; //批量同步结果数据
      this.batchNotifyResList = []; //批量通知结果数据

      this.exportSyncFlag = true; //导出同步结果按钮禁止点击
      this.exportNotifyFlag = true; //导出通知结果按钮禁止点击
      this.dialogDisabled = true; //批量通知按钮禁止点击
      this.finishDisabled = true; //关闭按钮禁止点击

      let arry = [];

      // 直接点击批量同步通知时
      if (val == 1) {
        this.tabData.forEach((v) => {
          if (v.active) {
            arry.push(v);
          }
        });

        if (arry.length <= 0) {
          this.$message.error("请勾选订单");
          return;
        } else {
          // 把allocOrderId相同的过滤掉
          let newArry = this.filterArry(arry);

          console.log("过滤后的数组", newArry);

          this.dialogShow = true;
          // return;
          this._getSyncProgress(newArry, 1); //开始循环调取同步接口 ,1为直接点击批量同步通知按钮打开的弹窗
        }
      } else {
        // 订单导入成功后打开批量同步通知弹框时,传入导入成功后的解析数据去请求批量请求同步接口
        this.dialogShow = true;

        this._getSyncProgress(this.importSuccessList, 2); //开始循环调取同步接口,2为订单导入时打开的弹窗
      }
    },

    // 进行批量同步
    async _getSyncProgress(arry, dialogType) {
      let arryLen = arry.length - 1;
      for (var i = 0; i <= arryLen; i++) {
        await this._getSync(arry[i].allocOrderId, arry, i, dialogType);
        // console.log("请求同步返回结果", aaa);
      }
    },
    // 批量请求同步接口
    _getSync(id, arry, index, dialogType) {
      console.log("请求批量同步的数据", arry);
      return new Promise((resolve) => {
        let type = 1; //不弹框
        splitTradeSync(id, type).then((res) => {
          let orderStatus = "";
          if (res.resultData) {
            if (res.resultData.orderStatus == "PROCESSING") {
              orderStatus = "分账处理中";
            } else if (res.resultData.orderStatus == "FAIL") {
              {
                orderStatus = "分账失败";
              }
            } else if (res.resultData.orderStatus == "SUCCESS") {
              orderStatus = "分账成功";
            }
          } else {
          }
          // 拿到批量同步结果的所有数据 ,无论成功或者失败 都拿到

          this.batchSyncResList.push({
            mchOrderNo: arry[index].mchOrderNo, //商户订单号
            syncStatus: res.resultData ? "成功" : "失败", //同步状态
            syncResult: res.resultData ? "同步成功" : res.errorMessage, //同步结果
            orderStatus: orderStatus, //订单状态
          });
          if (res.resultStatus) {
            this.successesNum += 1;

            if (dialogType == 1) {
              if (res.resultData.changes) {
                this.batchNotifyArry.push(arry[index]);
              }
            } else {
              // 订单导入时,过滤掉分账处理中的订单,去批量发通知
              if (res.resultData.orderStatus != "PROCESSING") {
                this.batchNotifyArry.push(arry[index]);
              }
            }
          }
          this.percentage = parseInt((this.successesNum / arry.length) * 100);
          // console.log("请求同步成功次数", this.successesNum);
          console.log("进度值", this.percentage);
          // console.log("循环索引", index);
          // 所有数据批量同步完成时
          if (index + 1 == arry.length) {
            // console.log("所有数据同步请求完成,成功进度为", this.percentage);
            console.log(
              "所有数据同步请求完成,可以批量发通知的数据为",
              this.batchNotifyArry
            );
            // console.log(
            //   "所有数据同步请求完成,批量同步结果数据为",
            //   this.batchSyncResList
            // );
            this.exportSyncFlag = false; //导出同步结果按钮允许点击
            this.$message.success("批量同步请求完成");

            // 所有数据批量同步请求完成后,订单导入时,三秒后自动批量请求通知
            if (dialogType == 2) {
              setTimeout(() => {
                this.startBatchNotify(dialogType, this.batchNotifyArry);
              }, 2000);
            }
            if (this.successesNum > 0) {
              // 至少有一次成功时,判断同步请求成功次数是否等于请求的数组长度,如果相等,说明全部请求成功,则显示√

              if (this.successesNum == arry.length) {
                this.progressStatus = "success";
              } else {
                // 部分请求成功时 ,说明部分请求成功,则显示!
                this.progressStatus = "warning";
              }
              this.dialogDisabled = false;
            } else {
              // 一次都没请求成功时
              this.progressStatus = "exception";
              this.percentage = 100;
            }
            this.finishDisabled = false; //关闭按钮允许点击
          }

          resolve();
        });
      });
    },
    // 表格点击同步时
    getSync(id, index) {
      console.log("表格点击同步时", id);
      splitTradeSync(id).then((res) => {
        if (res.resultStatus) {
          this.tabData[index].allocStatus = res.resultData.orderStatus;
          this.$message.success("同步请求成功");
        }
      });
    },

    // 点击批量通知按钮
    // dialogType=1时,为点击批量同步通知按钮后手动发起的批量通知
    // dialogType=2时,为订单导入时自动发起的批量通知
    startBatchNotify(dialogType, arry) {
      this.percentage = 0; //重置进度值
      this.successesNum = 0; //重置成功次数
      this.dialogDisabled = true; //批量通知按钮禁止点击
      this.progressStatus = null; //重置进度条颜色
      this.finishDisabled = true; //关闭按钮禁止点击

      if (this.batchNotifyArry.length > 0) {
        this._getNotify(this.batchNotifyArry, dialogType);
      } else {
        this.finishDisabled = false; //关闭按钮允许点击

        this.$message.error("暂无可通知商户");
        return;
      }
    },
    // 进行批量通知 await async
    // dialogType=1时,为点击批量同步通知按钮后手动发起的批量通知
    // dialogType=2时,为订单导入时自动发起的批量通知
    async _getNotify(arry, dialogType) {
      console.log("请求批量通知的数据为", arry);

      let arryLen = arry.length - 1;
      for (var i = 0; i <= arryLen; i++) {
        await this._setNotify(arry[i].allocOrderId, arry, i);
      }
    },
    // 批量通知接口
    _setNotify(id, arry, index) {
      return new Promise((resolve) => {
        let type = 1; //不弹框
        splitTradeNotify(id, type).then((res) => {
          //存入批量通知所有结果
          this.batchNotifyResList.push({
            mchOrderNo: arry[index].mchOrderNo, //商户订单号
            notifyStatus: res.resultStatus ? "成功" : "失败", //通知状态
            notifyResult: res.resultStatus ? "通知成功" : res.errorMessage, //通知结果
          });

          if (res.resultStatus) {
            this.successesNum += 1;
          }
          this.percentage = parseInt((this.successesNum / arry.length) * 100);
          // console.log("请求通知成功次数", this.successesNum);
          // console.log("进度值", this.percentage);
          // console.log("循环次数222", index);
          // 批量通知数据全部请求完成时
          if (index + 1 == arry.length) {
            // console.log("所有数据通知请求完成后,进度值为", this.percentage);
            this.exportNotifyFlag = false; //导出通知结果按钮允许点击
            this.$message.success("批量通知请求完成");

            if (this.successesNum > 0) {
              // 至少有一次成功时,判断同步请求成功次数是否等于请求的数组长度,如果相等,说明全部请求成功,则显示√

              if (this.successesNum == arry.length) {
                this.progressStatus = "success";
              } else {
                // 部分请求成功时 ,说明部分请求成功,则显示!
                this.progressStatus = "warning";
              }
            } else {
              // 一次都没请求成功时
              this.progressStatus = "exception";
              this.percentage = 100;
            }
            this.finishDisabled = false; //完成按钮允许点击
          }

          resolve();
        });
      });
    },
    // 表格单击通知时
    getNotify(id) {
      splitTradeNotify(id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("通知请求成功");
        }
      });
    },

    // 点击订单导入按钮时
    openOrderImport() {
      this.selMchId = ""; //清空上次选择的商户
      this.importSuccessList = []; //清空订单导入成功后返回的数据

      this.importShow = true;
    },

    // 订单导入选中商户时
    setMchId(val) {
      console.log("选中商户时", val);
      this.actionUrl = "";
      let mchId = "";
      mchId = val;
      let urlStr = "";
      urlStr = this.uploadUrl + mchId + "/" + 4;
      this.actionUrl = urlStr;
      console.log("选中商户时222222", this.actionUrl);
    },

    // 订单导入上传成功时
    orderUploadSuccess(res) {
      console.log("订单导入上传成功时", res);
      let arry = [];
      if (res.resultStatus) {
        res.resultData.forEach((e) => {
          arry.push({
            allocOrderId: e.split("&")[0],
            mchOrderNo: e.split("&")[1], //商户订单号
          });
        });
        this.importSuccessList = arry;
        console.log("订单导入上传成功后拿到的解析数据", this.importSuccessList);
        this.batchSync(2); //打开批量同步通知弹窗
        //一秒后关闭订单导入弹窗
        setTimeout(() => {
          this.importShow = false;
        }, 1000);
      } else {
        this.$message.error(res.errorMessage);
      }
    },
    //  移除上传的订单文件前
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`, "提示", {
        confirmButtonText: "确定",
        confirmButtonClass: "confirmButton", //确定按钮的自定义类名
        cancelButtonText: "取消",
        cancelButtonClass: "cancelButton", //取消按钮的自定义类名
        type: "warning",
        closeOnClickModal: true, //是否可通过点击遮罩关闭
        closeOnPressEscape: false, //是否可通过按下 ESC 键关闭
      });
    },
    // 点击确定移除订单文件时
    removeOrderFile() {
      // console.log("handleRemovehandleRemovehandleRemove", file);
      // console.log("handleRemovehandleRemovehandleRemove", fileList);
      this.fileList = []; //移除订单文件
    },
    //订单导入超过数量时
    handleExceed() {
      this.$message.error("超出上传上限,请先移除已上传文件");
    },
    //模板下载或导出同步结果或通知结果
    handleDownload(val) {
      import("@/vendor/Export2Excel").then((excel) => {
        let tHeader = [];
        let filterVal = [];
        let arry = [];
        // 下载模板
        if (val == 1) {
          this.fileName = "分账交易订单模板";
          tHeader = ["商户订单号"];
          filterVal = ["mchOrderNo"];
          arry = [
            {
              mchOrderNo: "(示例)123456",
            },
          ];
        } else if (val == 2) {
          // 导出同步结果
          this.fileName = "分账交易订单同步结果";
          tHeader = ["商户订单号", "同步状态", "同步结果", "订单状态"];
          filterVal = ["mchOrderNo", "syncStatus", "syncResult", "orderStatus"];
          arry = this.batchSyncResList;
        } else {
          // 导出通知结果

          this.fileName = "分账交易订单通知结果";
          tHeader = ["商户订单号", "通知状态", "通知结果"];
          filterVal = ["mchOrderNo", "notifyStatus", "notifyResult"];
          arry = this.batchNotifyResList;
        }

        const list = arry;

        const data = this.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.fileName,
          autoWidth: true,
          bookType: this.bookType,
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    // -----------------------------------------------------------------------------
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        this.query.keyword = this.query.keyword.trim();
      }
      getAllocfundOrders(this.query).then((res) => {
        if (res.resultStatus) {
          if (this.crumbs == "批量查询") {
            this.allSelect = false;

            if (res.resultData.orders) {
              res.resultData.orders.forEach((h) => {
                h.active = false;
              });
            }

            this.tabData = res.resultData.orders;
            if (!this.tabData || this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNum = res.resultData.totalNum;
          }
        }
      });
    },
    // 获取详情
    getDetail() {
      let params = {
        orderNoType: this.formData.orderNoType,
        orderNo: this.formData.orderNo.trim(),
      };
      if (!params.orderNo) {
        this.$message.error(
          "请输入" + this.orderNoType[this.formData.orderNoType]
        );
        return;
      }
      getAllocfundSearch(params).then((res) => {
        if (res) {
          console.log("按订单查询的数据", res);
          if (this.crumbs != "批量查询") {
            this.tabData = res.resultData;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
          }
        }
      });
    },
    // 获取下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res) {
          this.productDrop = res.resultData;
        }
      });
      merchantsDrop().then((res) => {
        if (res.resultStatus) {
          this.mchDrop = res.resultData;
        }
      });
    },

    // 关闭弹窗,取消勾选状态
    onclose(){
      this.dialogShow = false;
      this.tabData.forEach((v) => {
        v.active = false;
      });
      this.allSelect = false;
    },

    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.allSelect = false;

      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.allSelect = false;

      this.getLists(false);
    },

    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量查询") {
        this.getLists();
      } else {
        if (this.formData.orderNo) {
          this.getDetail();
        }
      }
    },
    // 详情
    tabShow(value) {
      this.$router.push({
        name: "account_fzjy_detail",
        query: {
          id: value.allocDetailId,
        },
      });
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      formData: this.formData,
    };
    this.setFzjyDetail(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime {
    width: 63.5%;
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 60px - 44.67px - 10px) / 2);
}
.progressBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
}
.required {
  color: #48b8b6;
}
.uploadBox {
  margin-top: 40px;
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-demo {
  word-break: normal;
  text-align: center;
  margin-right: 10px;
}
.tempText {
  color: #008aff;
}
.uploadButn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background-color: #48b8b6;
  font-size: 12px;
}

.addBt {
  width: 80px;
  height: 32px;
  font-size: 8px;
  border-color: #48b8b6;
  color: #ffffff;
  background: #48b8b6;
  margin-left: 20px;
  padding: 0;
}
.addBt:hover {
  background: #fff;
  border-color: #48b8b6;
  color: #48b8b6;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
